<template> 
   <div>
        <template-form  :initialFields="fields" :onSubmit="onSubmit">
            <template v-slot:notification>
                <message :type="ntf.type" :status="ntf.status" :message="ntf.message" />
            </template>
		</template-form>
	</div>
</template>
 
<script>
    import Form from './Form.vue';
    //import Message from '../../../../../components/Message.vue';
    //import { defaultErrors } from "../../../../../helpers";
    export default {
        props: { 
            update: {
                type: Boolean,
                default: false
            }
        },
/*         data : function() {
            return {
                isLoading: false,
                fields: {
                    name: '',
                    address: '',
                    phone: ''
                },
                ntf: {
                    type: 1,
                    status: false,
                    message: ''
                }
            };
        }, */
        data() {
            return {
                isLoading: false,
                fields: {
                    name: '',
                    address: '',
                    phone: ''
                },
                ntf: {
                    type: 1,
                    status: false,
                    message: ''
                }
            }
        },
        components: {
            'template-form': Form//, Message
            },
        methods: {
                async on1Submit(fields) {
                    console.log(fields);
                    this.isLoading = true;
                    
                    try {
                        //const response = await axios.post('/api/supplier', fields);
                        //console.log(response);
                        //this.fields = defaultFields();
                        
                        this.ntf = {
                            type: 1,
                            message: 'Success create Supplier',
                            status: true
                        };
                    } catch (error) {
                        this.ntf = {
                            type: 3,
                            //message: defaultErrors(error.response.data.errors),
                            status: true
                        };
                    }
                    this.isLoading = false;
                },
                async onSubmit(fields) {
                    this.isLoading = true;
                    console.log(fields);                    
                    try {
                        const response = await this.apiPosts(`suppliers/store`, fields);
                        console.log(response);                        
                         this.$router.push({
                            name: 'supplier'
                        });
                    } catch (error) {
                        console.log(error);                                                
                    }
                    this.isLoading = false;
                },
            }
    }
</script>